import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

// here added padding-top, that is only applied for mobile screens, becoz
// for tablet & desktop screens added pt-md-5 class
export const StyledMerchandisingSmallBannerUpgraded = styled.div`
  padding-top: clamp(${toRem(62)}, calc(${toRem(62)} + 24 * ((100vw - ${toRem(365)}) / 211)), ${toRem(86)});

  .merchandising-small-banner {
    border: ${baseVariables.border['borderWidth02']} solid ${baseVariables.color['neutral20']};
    border-radius: ${baseVariables.border['borderRadiusDefault']};
    box-shadow: ${baseVariables.shadows['shadowSubtle01']};

    &__image {
      width: ${toRem(155)};
      @media ${baseVariables.mediaQuery.md} {
        width: ${toRem(114)};
      }

      &.withVariation {
        width: ${toRem(180)};
      }

      &-container {
        margin-top: -${toRem(56)};

        &.loading {
          aspect-ratio: 3/2;
          width: ${toRem(155)};
          @media ${baseVariables.mediaQuery.md} {
            width: ${toRem(114)};
          }
        }
      }
    }

    &__description,
    .disclaimer-text {
      p {
        margin: 0;
      }
    }

    &__button {
      width: 100%;
      @media ${baseVariables.mediaQuery.md} {
        width: auto;
      }
    }

    &__variations {
      .total-amount {
        color: ${baseVariables.color.merch20};
      }
    }
  }
`;
