import clsx from 'clsx';
import { Image } from '@marriott/mi-ui-library';
import { IMerchandisingSmallBannerImage } from '../organisms/MerchandisingSmallBanner/MerchandisingSmallBanner.types';

export const MerchandisingSmallBannerImage = (props: IMerchandisingSmallBannerImage) => {
  const { isCobrand, logo, image, customImageClass, isTargetContentLoaded, isVariationsAvailable } = props;

  return (
    <div
      className={clsx('merchandising-small-banner__image-container mt-md-0', customImageClass, {
        'skeleton-loader loading': !isTargetContentLoaded,
      })}
    >
      {isTargetContentLoaded && (
        <Image
          customClass={clsx('merchandising-small-banner__image', {
            'withVariation mr-0 mr-md-5': isVariationsAvailable,
          })}
          altText={isCobrand ? logo?.altText || image?.altText : image?.altText}
          renditions={isCobrand ? [] : image?.renditions}
          dynamic={isCobrand ? false : image?.dynamic}
          defaultImageURL={isCobrand ? (logo ? logo?.imageSrc : image?.assetPath) : image?.assetPath}
        />
      )}
    </div>
  );
};
